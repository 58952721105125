import { Component } from "vue";

export const useBlockContainer = () => {
  const BlocksBlockProductList = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/BlockProductList.vue"),
    ),
  );

  const BlocksBlockPlpTopBanner = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/BlockPlpTopBanner.vue"),
    ),
  );

  const HomeNowTrendingSlider = computed(() =>
    defineAsyncComponent(
      () => import("@/components/pages/home/BlockNowTrendingNow.vue"),
    ),
  );

  const BlocksBlockProperties = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/BlockProperties.vue"),
    ),
  );

  const BlockRecentlyView = computed(() =>
    defineAsyncComponent(
      () => import("@/components/pages/home/BlockRecentlyView.vue"),
    ),
  );

  const BlockAccordion = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/BlockAccordion.vue"),
    ),
  );

  const BlockArticles = computed(() =>
    defineAsyncComponent(() => import("@/components/blocks/BlockArticles.vue")),
  );

  const BlockBestseller = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/BlockBestseller.vue"),
    ),
  );

  const BlockComparison = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/BlockComparison.vue"),
    ),
  );

  const BlockInstagram = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/BlockInstagram.vue"),
    ),
  );

  const MarketingCollections = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/MarketingCollections.vue"),
    ),
  );

  const BlockMedia = computed(() =>
    defineAsyncComponent(() => import("@/components/blocks/BlockMedia.vue")),
  );

  const BlockPromoted = computed(() =>
    defineAsyncComponent(() => import("@/components/blocks/BlockPromoted.vue")),
  );

  const BlockRichText = computed(() =>
    defineAsyncComponent(() => import("@/components/blocks/BlockRichText.vue")),
  );

  const BlockSale = computed(() =>
    defineAsyncComponent(() => import("@/components/blocks/BlockSale.vue")),
  );

  const BlockWishlist = computed(() =>
    defineAsyncComponent(() => import("@/components/blocks/BlockWishlist.vue")),
  );

  const BlockCategoriesDefault = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/BlockCategoriesDefault.vue"),
    ),
  );

  const BlockDefaultSlider = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/BlockDefaultSlider.vue"),
    ),
  );

  const BlockTopSlider = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/BlockTopSlider.vue"),
    ),
  );

  const BlockContactUs = computed(() =>
    defineAsyncComponent(
      () => import("@/components/pages/contact-us/BlockContactUs.vue"),
    ),
  );

  const CollectionCustomSlider = computed(() =>
    defineAsyncComponent(
      () => import("~/components/blocks/CustomBlocks/Home/HomeCollections.vue"),
    ),
  );

  const MapBlock = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/CustomBlocks/MapBlock.vue"),
    ),
  );

  const BlocksCustomBlocksFormContactUs = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/CustomBlocks/FormContactUs.vue"),
    ),
  );

  const BlockPlpEntityFilter = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/BlockPlpEntityFilter.vue"),
    ),
  );

  const HomeBreakFree = computed(() =>
    defineAsyncComponent(
      () => import("~/components/blocks/CustomBlocks/Home/HomeBreakFree.vue"),
    ),
  );

  const HomeDiscoverDiamondNexus = computed(() =>
    defineAsyncComponent(
      () =>
        import(
          "~/components/blocks/CustomBlocks/Home/HomeDiscoverDiamondNexus.vue"
        ),
    ),
  );

  const SizeToWeightSelectAShape = computed(() =>
    defineAsyncComponent(
      () =>
        import(
          "@/components/blocks/CustomBlocks/SizeToWeight/SizeToWeightSelectAShape.vue"
        ),
    ),
  );

  const SizeToWeightAdvantageSlider = computed(() =>
    defineAsyncComponent(
      () =>
        import(
          "@/components/blocks/CustomBlocks/SizeToWeight/SizeToWeightAdvantageSlider.vue"
        ),
    ),
  );

  const BlockFinancingSlider = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/CustomBlocks/BlockFinancingSlider.vue"),
    ),
  );

  const BlocksCustomBlocksShopByStyleEngagementList = computed(() =>
    defineAsyncComponent(
      () =>
        import(
          "~/components/blocks/CustomBlocks/ShopByStyle/ShopByStyleEngagementList.vue"
        ),
    ),
  );

  const BlocksCustomBlocksSocialPosts = computed(() =>
    defineAsyncComponent(
      () => import("~/components/blocks/CustomBlocks/SocialPosts.vue"),
    ),
  );

  const BlocksCustomBlocksHomeBreakFreeRedesign = computed(() =>
    defineAsyncComponent(
      () =>
        import(
          "~/components/blocks/CustomBlocks/Home/HomeBreakFreeRedesign.vue"
        ),
    ),
  );
  const BlocksCustomBlocksHomeDiscoverDiamondNexusRedesign = computed(() =>
    defineAsyncComponent(
      () =>
        import(
          "~/components/blocks/CustomBlocks/Home/HomeDiscoverDiamondNexusRedesign.vue"
        ),
    ),
  );

  interface ComponentsInterface {
    [key: string]: Component | string;
  }

  const defaultComponentOptions: ComponentsInterface = {
    catalog: markRaw(BlocksBlockProductList.value),
    accordion: markRaw(BlockAccordion.value),
    articles: markRaw(BlockArticles.value),
    bestseller: markRaw(BlockBestseller.value),
    categories: markRaw(BlockCategoriesDefault.value),
    comparison: markRaw(BlockComparison.value),
    entity: markRaw(BlocksBlockProperties.value),
    instagram: markRaw(BlockInstagram.value),
    "marketing-collections": markRaw(MarketingCollections.value),
    media: markRaw(BlockMedia.value),
    promoted: markRaw(BlockPromoted.value),
    "recently-viewed": markRaw(BlockRecentlyView.value),
    "rich-text-module": markRaw(BlockRichText.value),
    sale: markRaw(BlockSale.value),
    slider: markRaw(BlockDefaultSlider.value),
    "slider-2-columns": markRaw(HomeNowTrendingSlider.value),
    wishlist: markRaw(BlockWishlist.value),
    "top-slider": markRaw(BlockTopSlider.value),
    "plp-top-banner": markRaw(BlocksBlockPlpTopBanner.value),
  };

  const customComponentsOptions: ComponentsInterface = {
    "contact-us-block": markRaw(BlockContactUs.value),
    "collection-custom-slider": markRaw(CollectionCustomSlider.value),
    "location-map": markRaw(MapBlock.value),
    "form-contact-us": markRaw(BlocksCustomBlocksFormContactUs.value),
    "plp-shop-by": markRaw(BlockPlpEntityFilter.value),
    // "home-break-free": markRaw(HomeBreakFree.value), // TODO remove if useless
    // "home-discover-diamond-nexus": markRaw(HomeDiscoverDiamondNexus.value),// TODO remove if useless
    "home-discover-diamond-nexus": markRaw(
      BlocksCustomBlocksHomeDiscoverDiamondNexusRedesign.value,
    ),
    // "home-break-free": markRaw(HomeDiscoverDiamondNexus.value),// TODO remove if useless
    "home-break-free": markRaw(BlocksCustomBlocksHomeBreakFreeRedesign.value),
    "size-to-weight-select-a-shape": markRaw(SizeToWeightSelectAShape.value),
    "size-to-weight-advantage-slider": markRaw(
      SizeToWeightAdvantageSlider.value,
    ),
    "financing-payment-methods": markRaw(BlockFinancingSlider.value),
    "shop-by-style-engagement-list": markRaw(
      BlocksCustomBlocksShopByStyleEngagementList.value,
    ),
    "social-posts-block": markRaw(BlocksCustomBlocksSocialPosts.value),
  };

  function getComponent(name: string, identifier: string): Component | string {
    const componentMap = {
      "plp-shop-by-": "plp-shop-by",
    };

    for (const key in componentMap) {
      if (name.includes(key)) {
        name = componentMap[key];
        break;
      }
    }
    return (
      customComponentsOptions[name] || defaultComponentOptions[identifier] || ""
    );
  }

  return { defaultComponentOptions, customComponentsOptions, getComponent };
};
